<template>
  <div class="collaborators-list">
    <v-expand-transition>
      <div v-if="displayHeaderAction"
        class="collaborators-header-action d-flex align-center pl-5"
      >
        <span class="f-13 c-grey mr-2">{{ $t('collaborator_page_nb_users_selected', { nb_users_selected: selectedUsersCount }) }}</span>
        <v-btn
          @click="deactivate(selectedUsers)"
          class="fs-12"
          small
          :text="true"
          color="primary"
        >
          <v-icon left class="mr-1">
            mdi-sleep
          </v-icon>
          {{ $t('collaborator_page_deactivate_action') }}
        </v-btn>
        <v-btn
          @click="activate(selectedUsers)"
          class="fs-12"
          small
          :text="true"
          color="primary"
        >
          <v-icon left class="mr-1">
            mdi-sleep-off
          </v-icon>
          {{ $t('collaborator_page_activate_action') }}

        </v-btn>
      </div>
    </v-expand-transition>
    <v-data-table
      v-if="item.usersList"
      :headers="visibleHeaders"
      :items="item.usersList"
      :hide-default-footer="true"
      disable-pagination
      show-select
      mobile-breakpoint="0"
      striped
      class="collaborator-table f-12"
      item-key="email"
      key="'dt_' + item.place_id"
      :no-data-text="no_results_text"
      @input="onRowSelect"
      @toggle-select-all="selectAllRows"
   >
   <template v-slot:item.email="{ item }">
     <div class="d-flex align-center">
       <div class="user-email-info">
         <span class="c-primary fs-13">{{ item.email }}</span>
         <span>
            <v-chip
              v-if="item.deactivated"
              label
              class="f-12 ml-2"
              small
            >
             {{ $t('collaborator_deactivated') }}
         </v-chip>
         </span>
       </div>
     </div>
   </template>
   <template v-slot:item.lastConnection="{item}">
     <div class="d-flex f-12 c-grey align-center ga-1">
         <v-icon class="f-8" :class="bagdeColor(item)" small>mdi-circle</v-icon>
         <span v-if="item.lastConnection">{{ item.lastConnection }}</span>
         <span v-else>{{ $t('collaborator_page_newer') }}</span>
     </div>
   </template>
   <template v-slot:item.establisment="{item}" v-if="!$vuetify.breakpoint.xs">
     <div class="f-12 c-grey">
       <div v-if="item.allPlaces">
         {{ $t('collaborator_page_all_establishment')}}
       </div>
       <div v-else>
         {{ capitalizeFirstLetter(item.establisment.join(', '))  }}
       </div>
     </div>
   </template>
   <template v-slot:item.group="{item}" class="f-12 c-grey" v-if="!$vuetify.breakpoint.xs">
     <div class="f-12 c-grey">
       {{ item.group.join(', ') }}
     </div>
   </template>
   <template v-slot:item.actions="{item}" class="f-12 c-grey" v-if="hasRightEditCollaborators">
     <div class="f-12 c-grey">
      <v-menu bottom left>
        <template v-slot:activator="{ on, attrs }">
            <v-btn
                icon
                v-bind="attrs"
                v-on="on"
            >
                <v-icon>mdi-dots-horizontal</v-icon>
            </v-btn>
        </template>
        <v-list
          dense
          class="py-0"
        >
          <v-list-item v-if="item.deactivated" activate :key="item.email + 'activate' " @click="activate([item])">
            <v-list-item-icon class="mr-0">
              <v-icon small left color="primary">mdi-sleep-off</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="fs-12 c-primary">
                {{ $t('collaborator_page_activate_action') }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item v-else deactivate :key="item.email + '_deactivate' " @click="deactivate([item])">
            <v-list-item-icon class="mr-0">
              <v-icon small left color="primary">mdi-sleep</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="fs-12 c-primary">
                {{ $t('collaborator_page_deactivate_action') }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item v-if="!rightBarIsLoading" activate :key="`${item.email} + edit-user`" @click="handleRightbarUserOpen(item)">
            <v-list-item-icon class="mr-0">
              <v-icon small left color="primary">mdi-account-edit</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="fs-12 c-primary">
                {{ $t('collaborator_page_edit_action') }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
     </div>
   </template>
    </v-data-table>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  name: "CollaboratorsList",
  components: {},
  props: {
    item: {
      type: Object,
      required: false,
    },
    rightBarIsLoading: {
      type: Boolean,
      required: false,
    }
  },
  data() {
    return {
      headers: [
        { value: 'data-table-select', cellClass: 'pr-0 pl-4', class: 'pr-0 pl-4'},
        { text: this.$t('collaborator_page_collaborator'), value: 'email', class: 'collaborators_emails', cellClass: 'pl-0', class: 'pl-0'},
        { text: this.$t('collaborator_page_lastConnection'), value: 'lastConnection', class: 'customTd'},
        { text: this.$t('collaborator_page_perimeters'), value: 'establisment', class: 'customTd', align: 'lg-and-up', show: ['md', 'lg', 'xl']},
        { text: this.$t('collaborator_page_groups'), value: 'group', class: 'customTd', align: 'lg-and-up', show:['md', 'lg', 'xl']},
        { text: '', value: 'actions', class: 'customTd', align: 'end' },
      ],
      no_results_text: this.$t('collaborator_page_empty_table'),
      hoveredRow: null
    }
  },
  methods: {
    bagdeColor(item) {
      if (item.active){
        return 'c-carribean-green';
      }
      return 'c-red';
    },
    handleRightbarUserOpen(item) {
      this.$emit('toggleRightbarUser', true, item, 'edit')
    },
    async deactivate(users) {
      const response = await this.$api.wizville.collaborators.deactivate(users.map((user) => user.id))

      if (response.status === 'ok') {
        this.setUserDeactivatedStatus(response.deactivatedUserIds)
        this.$store.dispatch('notifySuccess', { timeout: 1000, message: response.message })
      } else {
        this.$store.dispatch('notifyError', { timeout: 1000, message: response.message})
      }
    },
    async activate(users) {
      const response = await this.$api.wizville.collaborators.activate(users.map((user) => user.id))

      if (response.status === 'ok') {
        this.setUserDeactivatedStatus(response.activatedUserIds, false)
        this.$store.dispatch('notifySuccess', { timeout: 1000, message: response.message })
      } else {
        this.$store.dispatch('notifyError', { timeout: 1000, message: response.message})
      }
    },
    setUserDeactivatedStatus(userIds, shouldDeactivate = true) {
      let updatedUsersList = [...this.item.usersList]
      userIds.forEach((id) => {
        const index = this.indexUsersListDataByUserId[id]
        this.item.usersList[index].deactivated = shouldDeactivate
      })
      this.emitUpdateUsersList(updatedUsersList, this.item)
    },
    onRowSelect(selectedUsers) {
      let updatedUsersList = [...this.item.usersList]
      updatedUsersList.forEach((item) => {
        item.selected = false
      })

      selectedUsers.forEach((item) => {
        let index = this.indexUsersListDataByUserId[item.id]
        updatedUsersList[index].selected = true
      })
      this.emitUpdateUsersList(updatedUsersList, this.item)
    },
    capitalizeFirstLetter(string) {
      if (!string) return '';
      return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
    },
    selectAllRows(props) {
      const self = this;
      props.items.forEach(item => item.selected = props.value)
    },
    emitUpdateUsersList(usersList) {
      this.$emit('updateUsersList', usersList, this.item);
    },
  },
  computed: {
    ...mapGetters([
      'hasRightEditCollaborators'
    ]),
    selectedUsers() {
      return this.item.usersList.filter(item => item.selected) || []
    },
    selectedUsersCount() {
      return this.selectedUsers.length
    },
    displayHeaderAction() {
      return this.selectedUsersCount && this.hasRightEditCollaborators
    },
    visibleHeaders() {
      const currentBreakpoint = this.$vuetify.breakpoint.name;
      return this.headers.filter(header => !header.show || header.show.includes(currentBreakpoint));
    },
    indexUsersListDataByUserId() {
      return this.item.usersList.reduce((hash, user, index) => {
        hash[user.id] = index
        return hash
      }, {})
    }
  },
}
</script>
<style lang="stylus" >
  @import '~@theme/colors.styl'
  .collaborators-list
    .v-data-table > .v-data-table__content > tbody > tr
      max-height: 48px
    .v-input.theme--light.v-input--selection-controls.v-input--checkbox
      max-height: 23px
      padding: 0px
      margin: 0
    tr.row-hover
      background-color: var(--catskill-white) !important
    .v-data-table
      tbody tr:nth-child(even)
        background-color: var(--palegrey);
      tbody tr:nth-child(odd)
        background-color: var(--white);
    .v-data-table-header
      background-color: var(--palegrey);
      color: var(--white);
    .user-email-info
      display: flex;
      align-items: center;
    td
      border: none!important
    .collaborator-table
      color: var(--palegrey);
    .v-expansion-panel-content__wrap
      padding: 0px
    .collaborator-table /deep/ th
      background-color: var(--palegrey)!important
      padding 8px 0 if($index == 1) ? 8px : 0
    .collaborator-table /deep/ th.collaborators_emails
      padding-left: 0px!important
    .collaborator-table /deep/ tr:nth-child(even) {background: var(--oslo-gray-50)}
    .collaborator-table /deep/ tr:nth-child(odd) {background: var(--white)}
    .collaborator-table /deep/ tr:hover {background-color: var(--catskill-white)!important}
    .collaborator-table /deep/ td { border: none!important }
    tr:hover {background-color: var(--catskill-white)!important}

    .collaborators-header-action
      height: 40px
      border-top: thin solid rgba(0, 0, 0, 0.12)

      .v-btn
        text-transform:none !important
        letter-spacing: normal

</style>
